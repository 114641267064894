import { defineStore } from 'pinia'

const nuxtApp = useNuxtApp()
const config = useRuntimeConfig()

export const useStandaloneStore = defineStore('standalone', {
  state: () => ({
    standaloneList: [],
  }),
  actions: {
    async fetchList() {
      const res = await $fetch('/system/devices/basic/list', {
        baseURL: config.public.baseURL,
        method: 'GET',
        headers: {
          'Accept-Language': nuxtApp.$i18n.locale.value,
        },
      })
      this.standaloneList = res.data
    },
  },
})
