<script setup>
import { twJoin } from 'tailwind-merge'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  level: {
    type: Number,
    default: 0,
  },
  links: {
    type: Array,
    default: () => [],
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  defaultOpen: {
    type: [Boolean, Number],
    default: undefined,
  },
  class: {
    type: [String, Object, Array],
    default: undefined,
  },
  ui: {
    type: Object,
    default: () => ({}),
  },
})

const appConfig = useAppConfig()

const config = computed(() => {
  const wrapper = twJoin(
    'space-y-4',
    props.level > 0 && '-ml-px',
  )

  const tree = twJoin(
    'bg-[#F7F8FA]',
    props.level > 0 ? '' : 'py-2.5',
  )

  return {
    wrapper,
    container: 'space-y-4',
    item: {
      padding: '',
      color: 'text-inherit',
    },
    button: {
      base: 'flex items-center px-4 gap-1.5 group w-full focus-visible:outline-primary',
      active: 'text-primary border-current',
      inactive: 'border-transparent',
      level: '-ml-px pl-3.5',
      icon: {
        base: 'w-5 h-5 flex-shrink-0 text-cobalt-500',
      },
      trailingIcon: {
        name: appConfig.ui.icons.chevron,
        base: 'w-5 h-5 ms-auto transform transition-transform duration-200 flex-shrink-0 mr-1.5',
        active: 'text-cobalt-500',
        inactive: 'text-cobalt-500 -rotate-90',
      },
      label: 'text-sm/6 truncate',
    },
    tree,
    links: {},
  }
})

const route = useRoute()
const { ui, attrs } = useUI('navigation.accordion', toRef(props, 'ui'), config, toRef(props, 'class'), true)

const items = computed(() => props.links?.map((link) => {
  const defaultOpen = !props.defaultOpen || (typeof props.defaultOpen === 'number' && props.level < props.defaultOpen) || (link.to && route.path.startsWith(link.to.toString()))

  return {
    label: link.label,
    icon: link.icon,
    slot: link.label.toLowerCase(),
    disabled: link.disabled,
    defaultOpen,
    closeOthers: false,
    children: link.children,
  }
}))
</script>

<template>
  <UAccordion
    :key="route.path"
    :items="items"
    :multiple="multiple"
    :ui="ui"
    v-bind="attrs"
  >
    <template #default="{ item, open }">
      <ULink
        :class="[ui.button.base, level > 0 && ui.button.level]"
        :active-class="ui.button.active"
        :inactive-class="ui.button.inactive"
      >
        <UIcon v-if="item.icon" :name="item.icon" :class="ui.button.icon.base" />

        <span :class="ui.button.label">{{ item.label }}</span>

        <UIcon
          v-if="!item.disabled"
          :name="ui.button.trailingIcon.name"
          :class="[ui.button.trailingIcon.base, open ? ui.button.trailingIcon.active : ui.button.trailingIcon.inactive]"
        />
      </ULink>
    </template>

    <template v-for="({ label }, index) in links" :key="index" #[label.toLowerCase()]="{ item }">
      <NavigationTree
        :links="item.children"
        :level="level + 1"
        :default-open="defaultOpen"
        :multiple="multiple"
        :class="ui.tree"
        :ui="{ links: ui.links }"
      />
    </template>
  </UAccordion>
</template>
