<script setup>
import {
  Popover,
  PopoverButton,
  PopoverPanel,
} from '@headlessui/vue'

const availableLocales = computed(() => {
  return useNuxtApp().$i18n.locales.value
})
function updateLocale(event, close) {
  useNuxtApp().$i18n.setLocale(event.code)
  close()
}
</script>

<template>
  <Popover class="relative">
    <PopoverButton>
      <!--      <nuxt-img
        :src="`${config.public.imageBaseURL}/icon_change_language.png`"
        alt="change language"
      /> -->
      <nuxt-img
        src="/images/icon_change_language.png"
        alt="change language"
      />
    </PopoverButton>

    <PopoverPanel v-slot="{ close }" class="absolute right-0 top-full z-30 w-32 overflow-hidden mt-3">
      <div class="rounded grid grid-cols-1 bg-white">
        <span
          v-for="loc in availableLocales"
          :key="loc.code" class="px-4 text-gray-500 py-3 text-nowrap hover:cursor-pointer"
          @click="updateLocale(loc, close)"
        >
          {{ loc.name }}
        </span>
      </div>
    </PopoverPanel>
  </Popover>
</template>

<style scoped lang="scss"></style>
