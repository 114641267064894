<script setup>
const config = useRuntimeConfig()

const navigation = {
  social: [
    {
      name: 'Facebook',
      href: 'https://facebook.com/profile.php?id=61556943366891',
      icon: 'i-uil:facebook',
    },
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/company/double100/mycompany/  ',
      icon: 'i-uil:linkedin',
    },
    {
      name: 'YouTube',
      href: 'https://www.youtube.com/@DOUBLE10022',
      icon: 'i-uil:youtube',
    },
  ],
}

const contactInformation = ref([
  {
    name: 'Phone',
    value: '18653185333',
  },
  {
    name: 'WhatsApp',
    value: '+86 139 5319 3276',
  },
  {
    name: 'WeChat',
    value: '13953193276',
  },
  {
    name: 'E-mail',
    value: 'ma@double100.net',
  },
])
</script>

<template>
  <footer
    class="bg-gradient-to-b from-prussian to-rosewood"
    aria-labelledby="footer-heading"
  >
    <h2 id="footer-heading" class="sr-only">
      Footer
    </h2>
    <div
      class="hidden lg:block mx-auto max-w-7xl px-8 pt-16 divide-y divide-[#797979] divide-solid"
    >
      <div class="xl:grid xl:grid-cols-2 xl:gap-8 pb-6">
        <div class="space-y-8 grow">
          <!--          <nuxt-img
            :src="`${config.public.imageBaseURL}/logo_white.png`"
            alt="logo"
          /> -->
          <nuxt-img
            src="/images/logo_white.png"
            alt="logo"
          />
          <p class="text-sm leading-6 text-white">
            {{ $t('Footer Text') }}
          </p>
          <div class="flex space-x-6">
            <a
              v-for="item in navigation.social"
              :key="item.name"
              :href="item.href"
              class="text-white hover:text-gray-500"
            >
              <span class="sr-only">{{ item.name }}</span>
              <u-icon :name="item.icon" class="h-6 w-6" />
            </a>
          </div>
        </div>
        <div class="md:grid md:grid-cols-2 md:gap-8">
          <div class="justify-self-end">
            <h3
              class="leading-6 text-white underline underline-offset-4 decoration-[#386AA5]"
            >
              {{ $t('Contact Information') }}
            </h3>
            <ul role="list" class="mt-6 space-y-4">
              <li
                v-for="item in contactInformation"
                :key="item.name"
                class="text-sm leading-6 text-white hover:text-gray-200"
              >
                <span>{{ item.name }}</span>
                <span class="mx-1 text-[#386AA5]">|</span>
                <span>{{ item.value }}</span>
              </li>
            </ul>
          </div>
          <div class="text-center place-content-end place-self-end">
            <p class="text-white">
              WeChat QR
            </p>
            <!--            <nuxt-img
              :src="`${config.public.imageBaseURL}/wx_code.png`"
              alt="WeChat QR image"
              class="w-28 h-28 mt-2"
            /> -->
            <nuxt-img
              src="/images/wx_code.png"
              alt="WeChat QR image"
              class="w-28 h-28 mt-2"
            />
          </div>
        </div>
      </div>
      <div class="pt-6">
        <p class="text-xs leading-5 text-white">
          &copy; 2024 Double100, Inc. All rights reserved.
        </p>
      </div>
    </div>

    <div class="lg:hidden px-4 py-5">
      <h3
        class="leading-6 text-white mb-6"
      >
        {{ $t('Contact Information') }}
      </h3>
      <div class="space-y-4 text-[#EAEAEA] text-sm leading-6 pb-4 border-b border-solid border-[#999999]">
        <div
          v-for="item in contactInformation"
          :key="item.name"
        >
          <a>{{ item.name }}</a>
          <span class="mx-1 text-[#656565]">|</span>
          <a class="">{{ item.value }}</a>
        </div>
      </div>
      <div class="my-5 pb-5 border-b border-solid border-[#999999]">
        <h3 class="text-[#C6C6C6] mb-5">
          Wechat QR
        </h3>
        <!--        <nuxt-img :src="`${config.public.imageBaseURL}/wx_code.png`" alt="wechat qrcode" width="75" height="75" class="block" /> -->
        <nuxt-img src="/images/wx_code.png" alt="wechat qrcode" width="75" height="75" class="block" />
      </div>
      <div class="my-5 pb-5 border-b border-solid border-[#999999]">
        <h3 class="text-[#C6C6C6] mb-5">
          {{ $t('Follow us') }}
        </h3>
        <a
          v-for="item in navigation.social"
          :key="item.name"
          :href="item.href"
          class="text-white mr-4"
        >
          <span class="sr-only">{{ item.name }}</span>
          <u-icon :name="item.icon" class="h-6 w-6" />
        </a>
      </div>
      <div class="pb-10">
        <p class="text-xs leading-5 text-white">
          &copy; 2024 Double100, Inc. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss"></style>
