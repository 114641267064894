<script setup>
const { headerLinks } = useNavigation()
</script>

<template>
  <div>
    <AppHeader :links="headerLinks" />
    <slot />
    <AppFooter />
    <ul
      class="fixed z-40 top-1/2 -translate-y-1/2 right-0 divide-y divide-[#EFEFEF] divide-solid flex flex-col shadow-xl bg-white rounded-tl-lg rounded-bl-lg"
    >
      <!--            <nuxt-link to="/contact" class="px-3 py-2.5">
              <u-icon name="i-uil:comment-alt-dots" class="h-6 w-6" style="color: #25D366" />
            </nuxt-link> -->
      <nuxt-link href="https://api.whatsapp.com/send?phone=8613953193276" class=" px-3 py-2.5">
        <u-icon name="i-uil:whatsapp" class="h-6 w-6" style="color: #25D366" />
      </nuxt-link>
      <nuxt-link href="mailto:ma@double100.net" class="px-3 py-2.5">
        <u-icon name="i-uil:envelope" class="h-6 w-6" style="color: #25D366" />
      </nuxt-link>
      <nuxt-link class="px-3 py-2.5 wechat-element">
        <u-icon name="i-tdesign:logo-wechat-stroke" class="h-6 w-6" style="color: #25D366" />
        <div class="bg-white rounded wechat-qrcode-element">
          <!--          <nuxt-img :src="`${config.public.imageBaseURL}/wx_code.png`" alt="wechat qrcode" class="block"/> -->
          <nuxt-img src="/images/wx_code.png" alt="wechat qrcode" class="block" />
        </div>
      </nuxt-link>
    </ul>
  </div>
</template>

<style scoped lang="scss">
/* 淡入 */
@keyframes fadein{
  0%{opacity:0;}
  100%{opacity:1;}
}

/* 淡入-从上 */
@keyframes fadeinT{
  0%{opacity:0;transform:translateY(-100px);}
  100%{opacity:1;transform:translateY(0);}
}

/* 淡入-从右 */
@keyframes fadeinR{
  0%{opacity:0;transform:translateX(100px);}
  100%{opacity:1;transform:translateX(0);}
}

/* 淡入-从下 */
@keyframes fadeinB{
  0%{opacity:0;transform:translateY(100px);}
  100%{opacity:1;transform:translateY(0);}
}

/* 淡入-从左 */
@keyframes fadeinL{
  0%{opacity:0;transform:translateX(-100px);}
  100%{opacity:1;transform:translateX(0);}
}

/* 淡出 */
@keyframes fadeout{
  0%{opacity:1;}
  100%{opacity:0;}
}

/* 淡出-向上 */
@keyframes fadeoutT{
  0%{opacity:1;transform:translateY(0);}
  100%{opacity:0;transform:translateY(-100px);}
}

/* 淡出-向右 */
@keyframes fadeoutR{
  0%{opacity:1;transform:translateX(0);}
  100%{opacity:0;transform:translateX(100px);}
}

/* 淡出-向下 */
@keyframes fadeoutB{
  0%{opacity:1;transform:translateY(0);}
  100%{opacity:0;transform:translateY(100px);}
}

/* 淡出-向左 */
@keyframes fadeoutL{
  0%{opacity:1;transform:translateX(0);}
  100%{opacity:0;transform:translateX(-100px);}
}

.wechat-element {
  position: relative;
  &:hover .wechat-qrcode-element{
    display: block;
    animation: fadein 0.8s linear forwards;
  }

  .wechat-qrcode-element {
    width: 160px;
    height: 160px;
    padding: 10px;
    border-radius: 10px;
    position: absolute;
    top: 0;
    right: 50px;
    display: none;
    z-index: 99;
  }
}
</style>
