import { createSharedComposable } from '@vueuse/core'
import { useTurnkeyStore } from '~/stores/useTurnkeyStore.js'
import { useStandaloneStore } from '~/stores/useStandaloneStore.js'

const route = useRoute()
const i18n = useNuxtApp().$i18n
const turnkeyStore = useTurnkeyStore()
const standaloneStore = useStandaloneStore()
turnkeyStore.fetchList()
standaloneStore.fetchList()
function _useNavigation() {
  const headerLinks = computed(() => {
    return [
      {
        label: i18n.t('Home'),
        to: '/',
        active: route.path === '/',
      },
      {
        label: i18n.t('Products'),
        to: '',
        active: route.path.startsWith('/products'),
        children: [
          {
            label: i18n.t('Turnkey'),
            to: '/products/turnkey',
            active: route.path.startsWith('/products/turnkey'),
            children: turnkeyStore.turnkeyList.map(item => ({ id: item.id, label: item.name, to: `/products/turnkey/${item.id}`, active: String(route.params.id) === String(item.id) })),
          },
          {
            label: i18n.t('Standalone'),
            to: '/products/standalone',
            active: route.path.startsWith('/products/standalone'),
            children: standaloneStore.standaloneList.map(item => ({ id: item.id, label: item.name, to: `/products/standalone/${item.id}`, active: String(route.params.id) === String(item.id) })),
          },
        ],
      },
      {
        label: i18n.t('About us'),
        to: '',
        active: route.path.startsWith('/about'),
        children: [
          {
            label: i18n.t('Customer Stories'),
            to: '/about/customer-stories',
            active: route.path.startsWith('/about/customer-stories'),
          },
          {
            label: i18n.t('News'),
            to: '/about/news',
            active: route.path.startsWith('/about/news'),
          },
          {
            label: i18n.t('FAQ'),
            to: '/about/faq',
            active: route.path.startsWith('/about/faq'),
          },
        ],
      },
      {
        label: i18n.t('Services'),
        to: '/services',
        active: route.path.startsWith('/services'),
      },
      {
        label: i18n.t('Contact us'),
        to: '/contact',
        active: route.path.startsWith('/contact'),
      },
      {
        label: '    ',
        to: '',
        icon: 'i-gis:globe-alt-o',
        children: [
          {
            label: 'English',
            to: '',
            active: i18n.locale.value === 'en',
          },
          {
            label: '简体中文',
            to: '',
            active: i18n.locale.value === 'zh-CN',
          },
          {
            label: 'Français',
            to: '',
            active: i18n.locale.value === 'fr-FR',
          },

        ],
      },
    ]
  })

  return {
    headerLinks,
  }
}

export const useNavigation = createSharedComposable(_useNavigation)
