<script setup>
import { ref } from 'vue'

defineProps({
  links: Array,
  default: () => [],
})

const scrollTop = ref(0)
const isTopScroll = ref(false)// 上移样式成立
const headerLinks = useNavigation().headerLinks

function handleScroll() {
  scrollTop.value = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop
}

// 监听top值的变化
watch(scrollTop, (newValue, oldValue) => {
  if (newValue > 50) {
    isTopScroll.value = newValue > oldValue
  }
})

const mobileNav = computed(() => {
  return headerLinks.value.slice(0)
})

const isMobile = ref(false)

onMounted(() => {
  isMobile.value = ('ontouchstart' in document.documentElement)
  window.addEventListener('scroll', handleScroll)
})
</script>

<template>
  <Header
    :links="links"
    title="Double100"
    class="flex lg:justify-between" :ui="{
      wrapper: `w-full ${isTopScroll ? 'top' : 'bg-white'} hover:bg-white transition duration-300`,
      container: 'w-full max-w-full mx-0',
      button: {
        base: 'text-[#7F7F7F]',
      },
    }"
  >
    <template #logo>
      <span class="sr-only">Double100</span>
      <!--      <nuxt-img
        class="h-8 w-auto "
        :src="`${config.public.imageBaseURL}/logo.png`"
        alt="logo"
      /> -->
      <nuxt-img
        class="h-8 w-auto "
        src="/images/logo.png"
        alt="logo"
      />
    </template>
    <template #right />
    <template #panel>
      <NavigationTree :links="mobileNav" :default-open="true" :multiple="false" />
    </template>
  </Header>
</template>

<style scoped lang="scss">
.top {
  transform: translateY(-90px);
  transition: all 1s;
}
</style>
