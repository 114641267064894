<script setup>
import { Dialog, DialogPanel, TransitionRoot, provideUseId } from '@headlessui/vue'
import { getSlotChildrenText } from '~/lib/slots'
import { useId } from '#imports'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  to: {
    type: String,
    default: '/',
  },
  title: {
    type: String,
    default: undefined,
  },
  links: {
    type: Array,
    default: () => [],
  },
  class: {
    type: [String, Object, Array],
    default: undefined,
  },
  ui: {
    type: Object,
    default: () => ({}),
  },
  isTopScroll: {
    type: Boolean,
    default: false,
  },
})

const appConfig = useAppConfig()

const config = computed(() => ({
  wrapper: `bg-white sticky top-0 z-50 ${props.isTopScroll ? 'top' : 'bg-white bg-opacity-100'}`,
  container: 'flex items-center justify-between gap-3 h-[--header-height]',
  left: 'lg:flex-1 flex items-center gap-1.5',
  center: 'hidden lg:flex',
  right: 'flex items-center justify-end gap-1.5',
  logo: 'flex-shrink-0 font-bold text-xl text-gray-900 dark:text-white flex items-end gap-1.5',
  panel: {
    wrapper: 'w-full fixed inset-0 z-50 overflow-y-auto bg-white lg:hidden',
    header: 'px-4 sm:px-6',
    body: 'pt-3 pb-6',
  },
  button: {
    base: 'lg:hidden',
    icon: {
      open: appConfig.ui.icons.menu,
      close: appConfig.ui.icons.close,
    },
  },
}))

const route = useRoute()
const slots = useSlots()
const { $ui } = useNuxtApp()
const { isHeaderDialogOpen } = useUIState()
const { ui, attrs } = useUI('header', toRef(props, 'ui'), config, toRef(props, 'class'), true)

const ariaLabel = computed(() => (props.title || (slots.logo && getSlotChildrenText(slots.logo())) || 'Logo').trim())

watch(() => route.fullPath, () => {
  isHeaderDialogOpen.value = false
})

provideUseId(() => useId())
</script>

<template>
  <header :class="ui.wrapper" v-bind="attrs">
    <slot name="top" />

    <UContainer :class="ui.container">
      <div :class="ui.left">
        <slot name="left">
          <NuxtLink to="/" :aria-label="ariaLabel" :class="ui.logo">
            <slot name="logo">
              {{ title || 'Double100' }}
            </slot>
          </NuxtLink>
        </slot>
      </div>

      <!--      <slot name="center"> -->
      <!--        <HeaderLinks :links="links" :class="ui.center" /> -->
      <!--      </slot> -->

      <div :class="ui.right">
        <HeaderLinks :links="links" :class="ui.center" />
        <language-switcher class="hidden lg:block ml-5" />
        <slot name="right" />

        <slot name="panel-button" :open="isHeaderDialogOpen">
          <UButton
            v-if="links.length || $slots.panel"
            :class="ui.button.base"
            variant="ghost"
            size="xl"
            v-bind="$ui?.button?.secondary"
            :aria-label="`${isHeaderDialogOpen ? 'Close' : 'Open'} Menu`"
            :icon="isHeaderDialogOpen ? ui.button.icon.close : ui.button.icon.open"
            @click="isHeaderDialogOpen = !isHeaderDialogOpen"
          />
        </slot>
      </div>
    </UContainer>

    <slot name="bottom" />

    <TransitionRoot :show="isHeaderDialogOpen" as="template">
      <Dialog as="div" @close="isHeaderDialogOpen = false">
        <DialogPanel :class="ui.panel.wrapper">
          <div :class="[ui.panel.header, ui.wrapper]">
            <div :class="ui.container">
              <div :class="ui.left">
                <slot name="left">
                  <NuxtLink to="/" :aria-label="ariaLabel" :class="ui.logo">
                    <slot name="logo">
                      {{ title || 'Double100' }}
                    </slot>
                  </NuxtLink>
                </slot>
              </div>

              <slot name="center" />

              <div :class="ui.right">
                <slot name="right" />

                <slot name="panel-button" :open="isHeaderDialogOpen">
                  <UButton
                    :class="ui.button.base"
                    v-bind="$ui?.button?.secondary"
                    variant="ghost"
                    size="xl"
                    :aria-label="`${isHeaderDialogOpen ? 'Close' : 'Open'} Menu`"
                    :icon="isHeaderDialogOpen ? ui.button.icon.close : ui.button.icon.open"
                    @click="isHeaderDialogOpen = !isHeaderDialogOpen"
                  />
                </slot>
              </div>
            </div>
          </div>
          <div :class="ui.panel.body">
            <slot name="panel">
              <AsideLinks :links="links" />
            </slot>
          </div>
        </DialogPanel>
      </Dialog>
    </TransitionRoot>
  </header>
</template>
