export function omit(object, keysToOmit) {
  const result = { ...object }
  for (const key of keysToOmit) {
    delete result[key]
  }
  return result
}
export function get(object, path, defaultValue) {
  if (typeof path === 'string') {
    path = path.split('.').map((key) => {
      const numKey = Number(key)
      return Number.isNaN(numKey) ? key : numKey
    })
  }
  let result = object
  for (const key of path) {
    if (result === void 0 || result === null) {
      return defaultValue
    }
    result = result[key]
  }
  return result !== void 0 ? result : defaultValue
}
